.main {
  width: 100%;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);

}
.box {
  width: 100%;
  height: 54px;

  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.activeIcon {
  transform: rotate(0deg);
  transition: ease-in-out 0.3s;
}
.inActiveIcon {
  transform: rotate(180deg);
  transition: ease-in-out 0.3s;
}
.options {
  border-radius: 12px;
  width: 100%;
}
.option {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 7px 0;
}
.option:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.check {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  padding: 1px;
}
.checked {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}
.labelSelected {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
