.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 52px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 65px;
}
@media screen and (max-width: 822px) {
  .footer {
    display: none;
  }
}
.mid {
  display: flex;
  align-items: center;
  column-gap: 67px;
}
.linkText {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
}
.footer img {
  cursor: pointer;
}
.right {
  display: flex;
  align-items: center;
  column-gap: 28px;
}
.link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eef1f5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Small Footer Style */

.footerSmall {
  display: block;
}
@media screen and (max-width: 630px) {
  .linksBox {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important; 
    grid-template-rows: 1fr !important;
    grid-column-gap: 10px !important;
    grid-row-gap: 10px !important;
  }
}
@media screen and (min-width: 822px) {
  .footerSmall {
    display: none;
  }
}
.logoBox {
  display: flex;
  justify-content: center;
  padding: 28px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.btmBox {
  margin-top: 6px;
  margin: 20px;
}
.linksBox {
  display: flex;
  justify-content: space-around;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px !important;
  grid-row-gap: 10px !important; */
}
.linkTextSmall {
  font-family: "Red Hat Display";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}
.iconsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 42px;
  margin-top: 26px;
  margin-bottom: 26px;
}
