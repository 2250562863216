.main {
  padding-bottom: 180px;
  padding-top: 34px;
  display: flex;
  justify-content: space-between;
}

.mainCont {
  display: flex;
  align-items: flex-start;
  position: absolute;
}

.middleBox {
  width: 500px;
  height: 600px;
  margin-top: -80px;
  position: relative;
  margin-left: 500px;
}

.leftHeading {
  position: absolute;
  font-family: "Whyte Inktrap";
  font-weight: 800;
  z-index: 2;
  font-size: 84px;
  line-height: 90px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: black;
  text-transform: uppercase;
}
.centerImageBox {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 40px;
  width: 83%;
  overflow: hidden;
}
.imgBg {
  position: absolute;
  z-index: -1;
}

.leftHeading2 {
  margin-left: -130%;
  margin-top: 50px;
  position: absolute;
  z-index: 3;
  font-family: "Whyte Inktrap";
  font-weight: 800;
  font-size: 84px;
  line-height: 90px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: white;
}

.blueText {
  font-family: "Red Hat Display";
  font-weight: 800;
  font-size: 24px;
  line-height: 80px;
  text-transform: uppercase;
  color: #67b7c2;
}

.description {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-top: 120px;
  width: 400px;
}
.leftBtmBox {
  margin-top: 110px;
  position: relative;
  width: fit-content;
}

.logo {
  position: absolute;
  bottom: -30%;
  left: -100px;
}
.rightImageBox {
  position: relative;
  width: fit-content;
}
.rightStar {
  position: absolute;
  bottom: -25%;
  z-index: -1;
  right: -120px;
}

.rightHeadingMain {
  position: absolute;
  font-family: "Whyte Inktrap";
  font-weight: 800;
  z-index: 1;
  font-size: 84px;
  right: -45%;
  bottom: 67.5px;
  line-height: 90px;
  display: flex;
  align-items: center;
  color: black;
  text-transform: uppercase;
}
.rightHeadingMain2 {
  position: absolute;
  font-family: "Whyte Inktrap";
  font-weight: 800;
  z-index: 3;
  font-size: 84px;
  right: -119.2%;
  bottom: 26px;
  line-height: 90px;
  display: flex;
  align-items: center;
  color: white;
  text-transform: uppercase;
}
/* ===============Main Style */

@media screen and (max-width: 1930px) and (min-width: 1870px) {
  .imgBg{
    width: 800px ;
  }
  .centerImageBox {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 45px;
    width: 620px;
  }
  .upperImage{
    width: 100%;
  }
  .middleBox {
    width: 720px;
    height: 827px;
    margin-top: -80px;
    position: relative;
    margin-left: 420px;
  }
  .leftHeading {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 2;
    font-size: 74px;
    line-height: 90px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: black;
    text-transform: uppercase;
  }
  .leftHeading2 {
    margin-left: -75%;
    margin-top: 50px;
    position: absolute;
    z-index: 3;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    font-size: 74px;
    line-height: 90px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: white;
  }
  .rightHeadingMain {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 1;
    font-size: 74px;
    right: -35.38%;
    bottom: 24px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
  }
  .rightHeadingMain2 {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 3;
    font-size: 74px;
    right: -74%;
    bottom: 17px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
  }
  .rigthImg {
    width: 295px;
  }
}
/* =============== */

@media screen and (max-width: 1753px) {
  .rightStar {
    position: absolute;
    bottom: -25%;
    right: -15%;
    width: 60%;
    z-index: -1;
  }
  .leftBtmBox {
    margin-top: 110px;
    position: relative;
    width: fit-content;
  }
  
  .logo {
    position: absolute;
    bottom: -30%;
    left: -40px;
  }
}
@media screen and (min-width: 1540px) {
  .right1 {
    display: none;
  }
  .leftBtmBox1 {
    display: none;
  }
}
@media screen and (max-width: 1540px) {
  .main {
    padding-bottom: 50px;
    padding-top: 34px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .left {
    width: 100%;
  }
  .right {
    display: none;
  }
  .leftBtmBox {
    display: none;
  }
  .leftBtmBox1 {
    position: relative;
    width: fit-content;
  }
  .right1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 90px;
    margin-bottom: 120px;
  }

  .rigthImg {
    height: 400px;
  }
  .leftImg1 {
    height: 400px;
  }

  
  .middleBox {
    width: 400px;
    height: 441px;
    margin-top: -70px;
    position: relative;
    margin-left: 450px;
  }
  .leftHeading {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 2;
    font-size: 64px;
    line-height: 64px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: black;
    text-transform: uppercase;
    margin-top: -5px;
  }
  .leftHeading2 {
    margin-left: -147.5%;
    margin-top: 35px !important;
    position: absolute;
    z-index: 3;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    font-size: 64px;
    line-height: 64px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: white;
    margin-top: 0px;
  }
  .rightHeadingMain {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 1;
    font-size: 64px;
    right: -40%;
    bottom: -11.5px;
    line-height: 64px;
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
  }
  .rightHeadingMain2 {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 3;
    font-size: 64px;
    right: -110.8%;
    bottom: 0px;
    line-height: 64px;
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1280px) {

  .rightHeadingMain {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 1;
    font-size: 64px;
    right: -31.5%;
    bottom: -11.5px;
    line-height: 64px;
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
  }
  .rightHeadingMain2 {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 3;
    font-size: 64px;
    right: -89%;
    bottom: 0px;
    line-height: 64px;
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1215px) {
  .rigthImg {
    height: 350px;
  }
  .leftImg1 {
    height: 350px;
  }
  .middleBox {
    width: 400px;
    height: 441px;
    margin-top: -70px;
    position: relative;
    margin-left: 400px;
  }
  .leftHeading {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 2;
    font-size: 50px;
    line-height: 50px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: black;
    text-transform: uppercase;
    margin-top: -5px;
  }
  .leftHeading2 {
    margin-left: -132.5%;
    margin-top: 35px !important;
    position: absolute;
    z-index: 3;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    font-size: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    color: white;
    margin-top: 0px;
  }
  .rightHeadingMain {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 1;
    font-size: 50px;
    right: -25.5%;
    bottom: -11.5px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
  }
  .rightHeadingMain2 {
    position: absolute;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    z-index: 3;
    font-size: 50px;
    right: -70%;
    bottom: 0px;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: white;
    text-transform: uppercase;
  }
}
@media screen and (min-width: 1075px) {
  .mainMobile{
    display: none;
  }

}
@media screen and (max-width: 1075px) {
  .main{
    display: none;
  }
  .mainMobile{
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .blueText {
    font-family: "Red Hat Display";
    font-weight: 800;
    font-size: 24px;
    line-height: 80px;
    text-transform: uppercase;
    text-align: center;
    color: #67b7c2;
  }
  .leftHeading {
    position: relative;
    font-family: "Whyte Inktrap";
    font-weight: 800;
    font-size: 54px;
    line-height: 60px;
    white-space:inherit;
    text-align: center;
    color: black;
    text-transform: uppercase;
  }
  .centerImageBox {
    position: relative;
    z-index: 2;
    top: auto;
    left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgBg {
    position: relative;
    z-index: -1;
    top: 0;
  }
  .upperImage{
    position: absolute; 
    top: 30px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
  }
  .middleBox {
    width: 80%;
    height: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lastBox{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 80px;
    margin-bottom: 40px;
  }
  .leftBox{
      width: 100%;
      position: relative;
  }
  .logo1{
    position: absolute;
    bottom: -60px;
    width: 140px;
  }
  .leftBoxImg1{
    width: calc(100% - 60px);
    margin-left: 60px;
  }
  .rightBox{
    position: relative;
    width: 100%;
  }
  .rigthImg1{
    width: calc(100% - 60px);
    margin-right: 60px;
  }
  .rightStar1{
    position: absolute;
    width: 140px;
    bottom: -60px;
    right: 0px;
    z-index: -1;
  }
} 

@media screen and (max-width: 600px) {
  .main{
    display: none;
  }
  .mainMobile{
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .blueText {
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #67B7C2;
  }
  .leftHeading {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 104%;
    margin-top: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
  .centerImageBox {
    position: relative;
    z-index: 2;
    top: auto;
    left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgBg {
    position: relative;
    z-index: -1;
    top: 0;
  }
  .upperImage{
    position: absolute; 
    top: 30px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 85%;
  }
  .middleBox {
    width: 80%;
    height: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .lastBox{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 17px;
    margin-bottom: 40px;
  }
  .leftBox{
      width: 100%;
      position: relative;
  }
  .logo1{
    position: absolute;
    bottom: -40px;
    width: 90px;
  }
  .leftBoxImg1{
    width: calc(100% - 40px);
    margin-left: 40px;
  }
  .rightBox{
    position: relative;
    width: 100%;
  }
  .rigthImg1{
    width: calc(100% - 40px);
    margin-right: 40px;
  }
  .rightStar1{
    position: absolute;
    width: 90px;
    bottom: -40px;
    right: 0px;
    z-index: -1;
  }
} 

@media screen and (max-width: 380px) {

  .leftHeading {
    font-family: 'Whyte Inktrap';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 104%;
    margin-top: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
  
} 