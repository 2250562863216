
.option {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 15px 10px;
  
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);
}
.labelSelected {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.option:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.main {
  position: absolute;
  background: #ffffff;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  padding: 20px;
  width: 100%;
  top: -65px;
  left: -20px;
}
.boxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.065);
}
.headerHeading {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #000000;
}
.boxFooter {
  display: flex;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.065);
}
.btn {
  width: 50%;
}
.delBtn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.delBtn span {
  padding-top: 3px;
}
.body{
    position: relative;
  height: 100%;

}
