.filterbar {
  display: flex;
  align-items: center;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-bottom: 30px;
  justify-content: space-between;
  gap: 30px;
  padding: 22px 0px;
}
.selectors {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.item {
  display: flex;
  align-items: center;
  gap: 26px;
}
.selectCont {
  width: 255px;
}
.rightBox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn {
  width: 172px;
  height: 54px;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}
.delBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 30px;
}
.delBtn:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 1522px) {
  .item {
    padding-bottom: 10px;
  }
  .textLabel {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .filterbarMobile {
    display: none;
  }
}

/* =================Mobile Css */

@media screen and (max-width: 800px) {
  .filterbar {
    display: none;
  }
  .filterbarMobile {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    margin-bottom: 30px;
    gap: 30px;
    padding: 20px 20px;
  }
  .filterBtn {
    display: flex;
    gap: 10px;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    padding: 8px 26px;
    color: #000000;
    border: 1.5px solid #000000;
    border-radius: 200px;
    cursor: pointer;
    user-select: none;
  }
  .box{
    max-width: 348px;
  }
}
.mobileFilterBarContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}