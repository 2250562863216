.main {
  width: 100%;
  min-height: 450px;
  background: #ffffff;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  border-radius: 21.4327px;
  padding: 40px 49px;

  position: relative;
}
.upperRow {
  background-color: #ffffff;
  display: flex;
  gap: 19px;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 19px;
  border-radius: 0px;
  position: relative;
  .badgesContmbl {
    display: none;
  }
}
.cardBottom {
  display: flex;
  background: #ffffff;
  padding: 0px;
  border-radius: 15px;
  margin-top: 38px;
  gap: 37px;
  .serviceBars {
    width: 36%;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .bar {
      width: 100%;
      max-width: 558px;
      height: 52px;
      left: 256px;
      top: 1922px;
      padding: 5px 19px;
      background: #ffffff;
      border: 0.45px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 8.5px 14.7px rgba(0, 0, 0, 0.065);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .left {
        width: fit-content;
        max-width: 80%;
        .serviceName {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          /* identical to box height, or 21px */

          color: #000000;
        }
        .serviceDuration {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;

          color: #000000;

          opacity: 0.5;
        }
      }
      .right {
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        font-family: "Red Hat Display";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        color: #000000;
      }
    }
    .unpremiumbar {
      width: 100%;
      max-width: 558px;
      height: 42px;
      left: 256px;
      top: 1922px;
      padding: 10px 0px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .left {
        width: fit-content;
        max-width: 80%;
        .serviceName {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          /* identical to box height, or 21px */

          color: #000000;
        }
        .serviceDuration {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;

          color: #000000;

          opacity: 0.5;
        }
      }
      .right {
        // width: 15%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        font-family: "Red Hat Display";
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 140%;
        color: #000000;
      }
    }
    .seeAll {
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #18d8f0;
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .coverImages {
    width: 60%;

    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    background: #ffffff;
  }
}
.rowLeft {
  display: flex;
  column-gap: 16px;
  max-width: 65%;
  flex-grow: 1;
  .servicesMother {
    width: 75%;
    .services {
      width: 100%;
      max-width: 600px;
      position: relative;
      overflow-x: scroll;
      height: fit-content;
      background: #ffffff;

      display: flex;
      flex-direction: row !important;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      flex-grow: 1;
      margin-top: 10px;

      &::-webkit-scrollbar {
        display: none !important;
      }
      .badge {
        min-width: fit-content !important;
      }
      .ethnicBadge {
        min-width: fit-content !important;
      }
    }
  }
}

.rowRightNew {
  width: 100%;
  max-width: 310px;
  min-width: 250px;
  height: 99px;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  margin-right: 57px;
  margin-top: 20px;
  .distance {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #000000;
    margin: 0;
    border-right: 0.5px solid rgba(0, 0, 0, 0.4);
    .d {
      margin: 0;
      margin-top: -10px;
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: #000000;

      opacity: 0.3;
    }
  }
  .startFrom {
    width: 50%;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #000000;
    margin: 0;

    .d {
      margin: 0;
      margin-top: -10px;
      font-family: "Red Hat Display";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: #000000;

      opacity: 0.3;
    }
  }
}
.rowRight {
  width: 100%;
  max-width: 310px;

  height: 99px;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  margin-right: 57px;
  margin-top: 20px;
}
.nameCont {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.nameCont p {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  color: #000000;
}
.locCont {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 6px;
  justify-content: flex-start !important;
}
.locCont p {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 21px;

  color: #000000;

  opacity: 0.3;
}
.badgesCont {
  margin-top: 20px;
}
.ethnicBadge {
  font-family: "Red Hat Display" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  color: #ff4df0 !important;
  background: rgb(255, 77, 240, 0.1) !important;
  padding: 9px 19px !important;
  border-radius: 8px !important;
  text-transform: none !important;
}
.badge {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #18d8f0 !important;
  padding: 9px 19px !important;
  background: rgba(24, 216, 240, 0.1) !important;
  border-radius: 8px !important;
}
.price {
  display: flex;
  align-items: center;
  column-gap: 11px;
}
.price p {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 74.86px;
  color: #000000;
}
.min {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.17px;
  color: #000000;
  opacity: 0.3;
}
.eye {
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.eye p {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.17px;
  color: #000000;
  opacity: 0.3;
}
.rightBtn {
  width: 172px;
  height: 66px;
}
.lowerRow {
  padding-top: 32px;
  border-top: 0.446515px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  column-gap: 14px;
}
.rowLeft {
  flex-grow: 1;
  gap: 19px !important;
  .avatarBox {
    width: 64px;
    height: 64px;

    border-radius: 50% !important;
    border: 0.893031px solid #b3b3b3;
    overflow: hidden;
  }

  .nameCont {
    text-align: center;
  }

  .locCont {
    justify-content: flex-start !important;
  }
}
.imgCont {
  width: 194px;
  max-height: 194px;
}
.servicesMbl {
  display: none;
}
.sponsred {
  position: absolute;
  width: 172px;
  height: 38px;
  top: -19px;

  background: #67b7c2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  color: #ffffff;
  column-gap: 4px;
  cursor: pointer;
}

.popular {
  position: absolute;
  top: 36px;
  right: 49px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.plusNum {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  position: sticky;
  right: -1px;
  color: #000000;
  padding-top: 8px;
  background: #ffffff;
  width: fit-content;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .main {
    width: 90% !important;
    max-height: 650px !important;
    margin: 0 auto;
    padding: 27px !important;
    .upperRow {
      margin-bottom: 12px !important;
      align-items: center !important;
      .rowLeft {
        max-width: 100%;
        gap: 7px !important;
        flex-direction: column !important;
        align-items: center !important;
        .avatarBox {
          width: 93px !important;
          height: 93px !important;
        }
        .servicesMother {
          width: 100% !important;
      
          
          .nameCont {
            width: 100% !important;

            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: 600 !important;
            font-size: 18px !important;
            line-height: 24px !important;
          }
          .nameCont p {
            font-size: 18px !important;          
          }
          .services {
            display: none !important;
            &::-webkit-scrollbar {
              display: none;
            }
            .badge {
              min-width: fit-content !important;
            }
            .ethnicBadge {
              min-width: fit-content !important;
            }
          }
          .locCont {
            // text-align: center !important;
            justify-content: center !important;

          }
          .locCont p {
            font-size: 14px !important;
            justify-content: center !important;
          }
        }
      }
    }
    .servicesMbl {
      width: 100%;
      max-width: 600px;
      // position: relative;
      overflow-x: scroll;
      height: 50px !important;
      background-color: #ffffff !important;
      display: flex !important;
      align-items: center;
      cursor: pointer;
      gap: 10px;
      // flex-grow: 1;
      margin: 0px 0px !important;
      margin-top: -10px !important;

      &::-webkit-scrollbar {
        display: none;
      }
      .ethnicBadge {
        min-width: fit-content;
        padding: 4px 9px !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
      }
      .badge {
        min-width: fit-content;
        padding: 4px 9px !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
      }
    }

    .cardBottom {
      width: 100% !important;
      max-width: 100% !important;
      flex-direction: column-reverse !important;
      margin-top: 0px !important;
      padding: 0px !important;
      gap: 1px !important;
      .serviceBars {
        width: 100% !important;
        max-width: 100% !important;
        // gap: 10px !important;
        .bar {
          width: 100% !important;
          max-width: 100% !important;
          box-shadow: none !important;
          border: none !important;
          padding: 0 !important;
          align-items: center !important;
          .left {
            .serviceName {
              padding: 0 !important;
              font-weight: 600 !important;
              font-size: 14px !important;
              line-height: 19px !important;
            }
            .serviceDuration {
              font-style: normal;
              font-weight: 500;
              font-size: 11px;
              line-height: 140%;

              color: #000000;

              opacity: 0.5;
            }
          }
          .right {
            min-width: fit-content !important;
          }
        }
      }
      .coverImages {
        width: 100% !important;
        height: fit-content;
        margin-top: -20px !important;
      }
    }
  }

  .badgesCont {
    display: none !important;
  }

  .rowLeft {
    gap: 19px !important;
    .avatarBox {
      width: 109px;
      height: 109px;
      border-radius: 50% !important;
      border: 0.893031px solid #b3b3b3;
      overflow: hidden;
    }
    .nameCont {
      text-align: center !important;
    }
    .locCont {
      justify-content: center !important;
    }
  }

  .rowRightNew {
    width: 100% !important;
    height: 59px !important;
    padding: 0px !important;
    margin: 0 auto !important;
    border-radius: 10px !important;
    .distance {
      height: 100% !important;
      font-size: 25px !important;
      height: 100% !important;
    }
    .startFrom {
      font-size: 25px !important;
    }
  }

  .mblMain {
    margin: 32px 20px;
    position: relative;
    background: #ffffff;
    border: 0.446515px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
    border-radius: 21.4327px;
    padding: 20px 12px;
    padding-top: 25px;
  }
  .sponsredMbl {
    position: absolute;
    width: 172px;
    height: 38px;
    top: -19px;
    left: 22px;
    background: #67b7c2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;

    color: #ffffff;
    column-gap: 4px;
    cursor: pointer;
  }
  .popular {
    position: absolute;
    top: 20px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }
  .avtcntr {
    display: flex;
    column-gap: 16px;
  }
  .locCont {
    align-items: flex-start;
  }
  .badgesCont {
    width: 100%;
    overflow: scroll;
    overflow-y: hidden;
    position: relative;
    /* hide scrollbar */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .badgesCont::-webkit-scrollbar {
    display: none;
  }
  .plusNum {
    position: sticky;
    right: 0px;
    bottom: 9px;
    margin-left: 10px;
    background: #ffffff;
    padding-left: 10px;
  }
  .topBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.165);
  }
  .price {
    padding-top: 13px;
    justify-content: center;
    padding-left: 17px;
  }
  .eye {
    padding-top: 13px;
    justify-content: center;
    border-right: 1px solid rgba(0, 0, 0, 0.165);
  }
  .upperRow {
    padding-bottom: 0 !important;
    display: flex !important;
    flex-direction: column;
    gap: 12px !important;
    border-bottom: none !important;
  }
  .lowerRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }
  .primiumContainer {
    width: 90% !important;
    max-height: 650px !important;
    margin: 0 auto;

    .bg2 {
      background: #ffffff !important;
    }
    .primiumMain {
      width: 95% !important;
      padding: 0px 12px 12px 12px !important;
      background: #ffffff;

      margin: 0 auto;
      border-radius: 10px !important;
      .upperRowPrimium {
        padding: 18px 0px 4px 0px !important;
        margin-top: 50px;
        border-radius: none;
        flex-wrap: nowrap !important;
        flex-direction: column !important;
        margin-bottom: 0px !important;
        gap: 15px !important;
        .rowLeftPrimium {
          height: 65px !important;
          gap: 6px !important;
          .AvatarGetis {
            width: 120px !important;
            max-height: 100% !important;
          }
          .servicesMother {
            width: 200px !important;
            margin-left: 20px !important;
            flex-grow: 1;
            .nameCont {
              font-family: "Red Hat Display";
              font-style: normal !important;
              font-weight: 600 !important;
              font-size: 20px !important;
              line-height: 26px !important;
            }
            .services {
              display: none !important;
            }
            .locCont p {
              font-size: 12px !important;
            }
          }
        }
        .avatarBoxPrimium {
          width: 112px !important;
          height: 112px !important;
          top: -35px !important;
        }
        .rowRightNew {
          width: 100% !important;

          height: 59px !important;
          padding: 0px !important;
          margin: 0 auto !important;
          border-radius: 10px !important;
          //   background-color: red !important;
          .distance {
            height: 100% !important;
            font-size: 20px !important;
            height: 100% !important;
            .d {
              padding-top: 4px !important;
            }
          }
          .startFrom {
            font-size: 20px !important;
            .d {
              padding-top: 4px !important;
            }
          }
        }
      }
      .servicesMbl {
        width: 100%;
        max-width: 600px;
        position: relative;
        overflow-x: scroll;
        height: fit-content;
        background: #ffffff;
        display: flex !important;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        flex-grow: 1;
        margin: 0px 0px 15px 0px !important;
        &::-webkit-scrollbar {
          display: none;
        }
        .ethnicBadge {
          min-width: fit-content;
          padding: 4px 9px !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 19px !important;
        }
        .badge {
          min-width: fit-content;
          padding: 4px 9px !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 19px !important;
        }
      }

      .cardBottom {
        width: 100% !important;
        flex-direction: column-reverse !important;
        // background-color: #d913a5 !important;
        margin-top: 0px !important;
        padding: 0px !important;
        gap: 10px !important;
        .serviceBars {
          width: 100% !important;
          gap: 0px !important;

          .bar {
            box-shadow: none !important;
            border: none !important;
            padding: 0 !important;
            align-items: center !important;
            .left {
              .serviceName {
                padding: 0 !important;
                font-weight: 600 !important;
                font-size: 14px !important;
                line-height: 19px !important;
              }
              .serviceDuration {
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 140%;
                color: #000000;
                opacity: 0.5;
              }
            }
            .right {
              min-width: fit-content !important;
              align-items: flex-start !important;
              padding-top: 7px !important;
              justify-content: flex-end;
            }
          }
        }
        .coverImages {
          width: 100% !important;
          height: fit-content;
          margin-top: -10px !important;
        }
      }
    }
  }
}

.primiumContainer {
  width: 100%;
  max-height: 450px;
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  position: relative;
  margin-top: 39px;
  background-color: transparent;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  border-radius: 21.43px;
  .bg2 {
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    position: absolute;
    z-index: -2;
    border-radius: 21.43px;
  }
  .bgPink {
    width: 100%;
    height: 102px;
    background: linear-gradient(101.49deg, #d913a5 6.69%, #18d8f0 98.11%);
    opacity: 0.1;
    position: absolute;
    z-index: -1;
    border-radius: 21.43px 21.43px 0px 0px;
  }
  .primiumMain {
    width: 100%;
    padding: 25px 20px 17px 20px;
    border-radius: 21.43px 21.43px 0px 0px;
    .upperRowPrimium {
      background-color: #ffffff;
      height: 150px;
      display: flex;
      flex-wrap: wrap;
      gap: 19px;
      justify-content: space-between;
      padding: 20px 20px 20px 69px;
      border-radius: 15px;
      position: relative;
      .rowLeftPrimium {
        display: flex;
        align-items: center;
        gap: 33px;
        flex-grow: 1;
        margin-top: -10px;
        .AvatarGetis {
          width: 160px;
          height: 139px;
        }

        .avatarBoxPrimium {
          width: 139px;
          height: 139px;
          border-radius: 50%;
          border: 0.893031px solid #b3b3b3;
          position: absolute;
          top: -20px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .servicesMother {
          width: 100%;
          // margin-left: 20px !important;
          
          .services {
            width: 100%;
            max-width: 520px;
            position: relative;
            overflow-x: scroll;
            height: fit-content;
            background: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 10px;
            flex-grow: 1;

            &::-webkit-scrollbar {
              display: none;
            }
            .ethnicBadge {
              width: fit-content;
              min-width: fit-content;
              padding: 8px 20px;
              font-family: "Red Hat Display";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;
              color: #18d8f0;
            }
            .badge {
              width: fit-content;
              min-width: fit-content;
              padding: 8px 20px;
              font-family: "Red Hat Display";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 21px;
              color: #18d8f0;
            }
          }
        }
      }
      .rowRightNew {
        width: 100%;
        max-width: 310px;
        min-width: 250px;
        height: 99px;
        background: #f7f7f7;
        border-radius: 10px;
        padding: 14px;
        display: flex;
        margin-right: 57px;
        margin-top: 10px;
        .distance {
          width: 50%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          color: #000000;
          margin: 0;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          .d {
            margin: 0;
            margin-top: -10px;
            font-family: "Red Hat Display";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            color: #000000;

            opacity: 0.3;
          }
        }
        .startFrom {
          width: 50%;

          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          color: #000000;
          margin: 0;

          .d {
            margin: 0;
            margin-top: -10px;
            font-family: "Red Hat Display";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;

            color: #000000;

            opacity: 0.3;
          }
        }
      }
    }
    .servicesMbl {
      display: none;
    }
    .cardBottom {
      display: flex;
      background: #ffffff;
      padding: 20px 26px 20px 26px;
      margin-top: 10px;
      border-radius: 15px;
      gap: 37px;
      .serviceBars {
        width: 36%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .bar {
          width: 100%;
          max-width: 558px;
          height: 52px;
          left: 256px;
          top: 1922px;
          padding: 5px 19px;
          background: #ffffff;
          border: 0.45px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8.5px 14.7px rgba(0, 0, 0, 0.065);
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          .left {
            width: fit-content;
            max-width: 80%;
            .serviceName {
              font-family: "Red Hat Display";
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 140%;
              /* identical to box height, or 21px */

              color: #000000;
            }
            .serviceDuration {
              font-family: "Red Hat Display";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 140%;

              color: #000000;

              opacity: 0.5;
            }
          }
          .right {
            width: 15%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            font-family: "Red Hat Display";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
            color: #000000;
          }
        }
        .seeAll {
          font-family: "Red Hat Display";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #18d8f0;
          margin-top: 5px;
          cursor: pointer;
        }
      }
      .coverImages {
        width: 60%;

        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        background: #ffffff;
      }
    }
  }
}
