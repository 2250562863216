/* modal */
.modalApp {
  position: absolute;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  z-index: 100;
}
.modal {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  z-index: 1; /* Overlay effect: positioned over other containers */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  overflow: auto; /* Enable scroll if needed */
  padding-top: 80px; /* Location of the content container */
}
.modal_content {
  background-color: white;
  width: 90%; /* Width in proportion to its parent container*/
  max-width: 685px; /* Max width where it stops expanding */
  height: 612px; /* Height in proportion to its parent container */
  margin: auto; /* Auto margin according to the element width */
  padding: 1px;
  border-radius: 20px; /* Optional. Rounds container corners */
}

.modalclose {
  color: #aaaaaa;
  float: right; /* Positioned to the right of the parent container whichever size it is */
  font-weight: bold;
  margin-right: 25px !important;
  margin-top: 15px;
}
.modalclose:hover,
.modalclose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modalFooter {
  /* background-color: red; */
}
/* modal content */
.authModalFooter {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  padding: 49px 103px 60px 103px;
}
.modalControler {
  padding-left: 20px !important;
}
.bottomLine {
  opacity: 0.4;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  height: 0px;
  width: 100%;
}

.termsOfService {
  color: #18d8f0;
  font-family: 'Red Hat Display';

  text-decoration: underline;
  text-decoration-thickness: 3px;
text-underline-offset: 3px;
}
.LoginText {
  margin-top: 139px;
  font-family: "Whyte Inktrap";
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
.googleLogoIcon {
  margin-right: 10px;
}
.button {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
}
.fbText {
  margin-right: -20px;
}
@media (max-width: 430px) {
  .LoginText {
    margin-top: 113px;
  }
  .authModalFooter {
    padding: 44px 23px 44px 23px;
  }
}
