.layout {
  padding-bottom: 32px;
  position: relative;
  overflow: hidden;
}

.copyright {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #000000;
  text-align: center;
}
.main {
  min-height: 60vh;
}
.leftBg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.rightBg {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: -1;
}

/* for blog top hero section */
.blog__top__leftBg {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.blog__top__rightBg {
  position: absolute;
  right: -100px;
  top: -60px;
  z-index: -1;
}
.blog__top__bottomBg {
  position: absolute;
  left: 0px;
  top: 500px;
  z-index: -1;
}
.blog__top__rightBg12 {
  position: absolute;
  right: -200px;
  top: 700px;
  z-index: -1;
}
/* end hero section */

/* for only blog and blog details page */
.blog__leftBg {
  position: absolute;
  left: 0px;
  top: 1406px;
  z-index: -1;
}

.blog__rightBg {
  position: absolute;
  right: 0px;
  top: 1306px;
  z-index: -1;
}

@media screen and (max-width: 1600px) {
  .blog__top__bottomBg {
    position: absolute;
    left: 0px;
    top: 200px;
    z-index: -1;
  }
  .blog__top__rightBg12{
    position: absolute;
    right: 0px;
    top: 200px;
    z-index: -1;
  }
  .blog__leftBg {
    top: 1106px;
  }

  .blog__rightBg {
    top: 1020px;
  }
}

@media screen and (max-width: 750px) {
  /* blog top section */

  .blog__top__leftBg,
  .blog__top__rightBg,
  .blog__top__bottomBg,.blog__top__rightBg12 {
    display: none;
  }

  .blog__top__rightBgSM {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -1;
    display: block;
  }
  .blog__top__bottomBgSM {
    position: absolute;
    left: 0px;
    top: 250px;
    z-index: -1;
    display: block;
  }
  /*  */
  .leftBg {
    display: none;
  }

  .rightBg {
    display: none;
  }

  .blog__leftBg {
    display: none;
  }

  .blog__rightBg {
    display: none;
  }

  /* left bg blog */

  .blog__leftBgOne {
    position: absolute;
    left: 0px;
    top: 1126px;
    z-index: -1;
    display: block;
  }
  .blog__leftBgTwo {
    position: absolute;
    left: 0px;
    top: 2116px;
    z-index: -1;
    display: block;
  }
  .blog__leftBgThree {
    position: absolute;
    left: 0px;
    top: 3006px;
    z-index: -1;
    display: block;
  }
  .blog__leftBgFour {
    position: absolute;
    left: 0px;
    top: 3893px;
    z-index: -1;
    display: block;
  }

  /* right bg blog */
  .blog__rightBgOne {
    position: absolute;
    right: 0px;
    top: 590px;
    z-index: -1;
    display: block;
  }
  .blog__rightBgTwo {
    position: absolute;
    right: 0px;
    top: 1740px;
    z-index: -1;
    display: block;
  }
  .blog__rightBgThree {
    position: absolute;
    right: 0px;
    top: 2550px;
    z-index: -1;
    display: block;
  }
  .blog__rightBgFour {
    position: absolute;
    right: 0px;
    top: 3400px;
    z-index: -1;
    display: block;
  }
  .blog__rightBgFive {
    position: absolute;
    right: 0px;
    top: 4420px;
    z-index: -1;
    display: block;
  }
}
.leftBgSM {
  position: absolute;
  left: 0px;
  top: 60vh;
  z-index: -1;
}
.rightBgSM {
  position: absolute;
  right: 0px;
  top: 5vh;
  z-index: -1;
}
@media screen and (min-width: 750px) {
  .leftBgSM {
    display: none;
  }
  .rightBgSM {
    display: none;
  }

  /* left bg */
  .blog__leftBgOne,
  .blog__leftBgTwo,
  .blog__leftBgThree,
  .blog__leftBgFour {
    display: none;
  }

  /* right bg */
  .blog__rightBgOne,
  .blog__rightBgTwo,
  .blog__rightBgThree,
  .blog__rightBgFour,
  .blog__rightBgFive {
    display: none;
  }

  .blog__top__rightBgSM,
  .blog__top__bottomBgSM {
    display: none;
  }
}
