.main {
  width: 100%;
  position: relative;
}
.box {
  width: 100%;
  height: 54px;
  background: #ffffff;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  border-radius: 200px;
  padding-left: 28px;
  padding-right: 28px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.activeIcon {
  transform: rotate(0deg);
  transition: ease-in-out 0.3s;
}
.inActiveIcon {
  transform: rotate(180deg);
  transition: ease-in-out 0.3s;
}
.options {
  position: absolute;
  background: #ffffff;
  opacity: 0.9;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  border-radius: 12px;
  width: 100%;
  margin-top: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
  max-height: 300px;
  overflow-y: auto;
}
.container {
  position: relative;
  z-index: 10;
}
.option {
  margin-top: 0px;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 6px 18px;
}
.option:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.check {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  padding: 1px;
}
.checked {
  border-radius: 2px;
  width: 100%;
  height: 100%;
}
/* search input */
.searchInputBox {
  height: 54px;
  background: #ffffff;
  border: 0.446515px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8.49842px 14.9688px rgba(0, 0, 0, 0.065);
  border-radius: 200px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}
.serchInput {
  width: 130px;
}
@media screen and (max-width: 430px) {
  .serchInput {
    width: 100px;
  }
}
@media screen and (max-width: 385px) {
  .serchInput {
    width: 80px;
  }
}
