.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 17px;
  padding-bottom: 36px; */
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.megamenulinkText {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  cursor: pointer;
}
.megeMenu {
  position: absolute;
  top: 100%;
  /* right: 50%; */
  border-radius: 10px;
  z-index: 100;
  box-shadow: -3px 2px 9px 1px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: -3px 2px 9px 1px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: -3px 2px 9px 1px rgba(0, 0, 0, 0.45);
  background-color: white;
  opacity: 0;
  visibility: hidden;
  display: flex;
  padding-inline: 45px;
  padding-bottom: 35px;
  padding-top: 15px;
}
.menuWrapper {
  padding: 20px;
}
.menuWrapper:hover .megeMenu {
  opacity: 1;
  visibility: visible;
}
.megeMenu:hover {
  opacity: 1;
  visibility: visible;
}
.megeMenuLeftBox {
  padding-inline: 20px;
  padding-bottom: 20px;
  display: flex;
}

.innerBox:nth-child(1) {
 width: 300px !important;
}
.innerBox:nth-child(2) {
  width: 230px !important;
 }
.leftInnerBox {
  margin-top: 25px;
  width: 80%;
}
.megemenyItemTitle {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.megaMenuSubtitle {
  margin-top: 4px;
  color: #6e6d7a;
  font-size: 13px;
  word-break: break-word;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.megaMenuSubtitleList {
  margin-top: 4px;
  color: #6e6d7a;
  font-size: 13px;
  word-break: break-word;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 20px;
}

@media screen and (max-width: 750px) {
  .header {
    display: none;
  }
}

.leftbox {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 34px;
  position: relative;
}
.right {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 34px;
}

.linkText {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin: 0;
  cursor: pointer;
}
.header img {
  cursor: pointer;
}

/* Small Header Style */

.headerSmall {
  display: block;
}

@media screen and (min-width: 750px) {
  .headerSmall {
    display: none;
  }
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.linkTextSmall {
  font-family: "Red Hat Display";
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  cursor: pointer;
}
.toggle {
  cursor: pointer;
}
